import logo from './logo.svg';
import Navbar from './components/Navbar.jsx';
import './App.css';
import Landing from './components/Landing.jsx';
import Job from './components/Job.jsx';
import Project from './components/Project.jsx';
import ProjectDetail from './components/ProjectDetail.jsx';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import coding_projects from "./json_data/coding_projects.js"

function App() {
  return (
    <Router>
      <div>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Landing/>}></Route>
          <Route path="/experience" element={<Job/>}></Route>
          <Route path="/project" element={<Project/>}></Route>
          <Route path="/project/:projectID" element={<ProjectDetail projects={coding_projects}/>}></Route>
          <Route path="/:projectID" element={<ProjectDetail projects={coding_projects}/>}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
