import React from "react";
import Resume from '../assets/resume3.pdf'
import {Link} from 'react-router-dom'

const Navbar = () => {
    return (
        <nav className="navbar sticky-top navbar-expand-lg bg-primary navbar-dark">
            <div className="container d-flex justify-content-between">
                <Link to="/" className="navbar-brand text-info"> BYSL</Link>
                <button className="navbar-toggler" type="button"
                    data-bs-toggle="collapse" data-bs-target="#navmenu">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navmenu">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item"><Link to="/" className="nav-link"> Home </Link></li>
                        <li className="nav-item"><Link to="experience" className="nav-link"> Experience</Link></li>
                        <li className="nav-item"><Link to="project" className="nav-link"> Projects</Link></li>
                        <li className="nav-item"><a href={Resume} className="nav-link" target="_blank">Resume</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;