import React from "react";
import "../style/Project.css"
import {BrowserRouter as Router, Route, Link, useParams} from 'react-router-dom';

const ProjectCard = (props) => { 

    const {title , id, context, thumbnail, date, skillset, website} = props;

    return (
        <section className="col-md-4" id="">
            <div className="card d-flex flex-column card-container px-4 py-3">
                <div className="image-container">
                    <img className="col-12" src={thumbnail} alt=""/>
                </div>
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{date}</p>
                </div>
                <div className="d-flex justify-content-center justify-content-md-start">
                    <Link to={`${id}`} className="btn btn-primary w-50">
                        Learn More
                    </Link>
                </div>
                {/* <button type="button" className="col-5 btn btn-primary"> learn more </button> */}
            </div>
        </section>
    )
}

export default ProjectCard;