import React from "react";
import { useParams } from "react-router-dom";

const ProjectDetail = (props) => {
    const {projects} = props;
    const {projectID} = useParams();

    const curProject = projects.find((project) => project.id == projectID)
    return(
        <section className="text-light pt-3 container" id="projectDetail">
            <div className="d-flex justify-content-end">
                {curProject.hasCode ? <a className="btn btn-secondary"> Source Code </a> : <div></div>}
                {curProject.hasLink ? <a href={curProject.website_link} className="btn btn-secondary"> Website Link </a> : <div></div>}
            </div>
            <div className="d-md-flex mt-3 mt-md-0 flex-column justify-content-between">
                <div className="text-primary d-flex flex-column mt-md-5">
                    <h1>{curProject.title}</h1>
                    <div className="mb-2">
                        {curProject.skill_list.map((skill) =>
                            <span className="badge badge-primary me-2 bg-light text-primary">{skill}</span>
                        )}
                    </div>
                    <p>{curProject.description}</p>
                </div>
                <img className="col-12"src={curProject.image} alt="" />
            </div>
        </section>
    )
}

export default ProjectDetail