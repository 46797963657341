import Home from './Home.jsx';
import Skill from './Skill.jsx';
import Job from './Job.jsx';
import Project from './Project.jsx';
import Footer from './Footer.jsx';

const Landing = () => {
    return(
        <div>
            <Home/>
            <Project/>
            <Job/>
            <Skill/>
            <Footer/>
        </div>
    )
}

export default Landing