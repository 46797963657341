import React from "react";

const Skill = () => {
    return (
        <section className="" id="skill">
            <div className="container">
                <div className="d-flex flex-column my-5">
                    {/* <div> 
                        <p className="text-center my-5 text-muted">Proficient in over <span className="fw-bold">10+</span> backend, frontend, server-side languages, and frameworks as well as database management</p>
                    </div> */}
                    <div className="d-flex justify-content-around px-md-5">
                        <i class="devicon-python-plain colored" style={{fontSize: '50px'}}></i>
                        <i class="devicon-java-plain colored" style={{fontSize: '50px'}}></i>
                        <i class="devicon-react-original colored"  style={{fontSize: '50px'}}></i>
                        <i class="devicon-javascript-plain colored"  style={{fontSize: '50px'}}></i>
                        <i class="devicon-bootstrap-plain colored"  style={{fontSize: '50px'}}></i>
                        {/* <img className="col-1" src={require('../assets/skill2.png')} alt="" />
                        <img className="col-1" src={require('../assets/skill1.png')} alt="" />
                        <img className="col-1" src={require('../assets/skill3.png')} alt="" />
                        <img className="col-1" src={require('../assets/skill4.png')} alt="" />
                        <img className="col-1" src={require('../assets/skill5.png')} alt="" /> */}
                    </div>
                    <div className="d-flex justify-content-around px-md-5 pt-5">
                        <i class="devicon-docker-plain colored" style={{fontSize: '50px'}}></i>
                        <i class="devicon-firebase-plain colored" style={{fontSize: '50px'}}></i>
                        <i class="devicon-php-plain colored" style={{fontSize: '50px'}}></i>
                        <i class="devicon-postgresql-plain colored" style={{fontSize: '50px'}}></i>
                        <i class="devicon-amazonwebservices-original colored" style={{fontSize: '50px'}}></i>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skill; 