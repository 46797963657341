import React from "react";
import ProjectCard from './ProjectCard';
import coding_projects from "../json_data/coding_projects.js"
import { Link } from "react-router-dom";

const Project = () => {
    const coding_project_list = coding_projects

    return (
        <section className="py-4" id="projects">
            {/* <h2 className="text-center text-secondary my-5">
                    PROJECTS
            </h2> */}

            {/* <ProjectCard title={coding_project[0].title} context={coding_project[0].description} image={coding_project[0].image} date={coding_project[0].date} skillset={coding_project[0].skill_list} ></ProjectCard>  */}
            <div className="row gx-3 gy-4 mx-3 mt-4">
                {coding_project_list.map((project) => (
                        <ProjectCard id={project.id} title={project.title} context={project.description} image={project.image} thumbnail={project.thumbnail} date={project.date} skillset={project.skill_list} website={project.website_link}></ProjectCard>
                ))}
            </div>
            {/* <div className="row gy-5">
                <div className="d-md-flex justify-content-between pb-md-0 pb-5">
                    <div className="col-md-5 bg-light">
                        <img className="col-12" src={require('../assets/eagleseek.png')} alt="" />
                    </div>
                    <div className="col-md-5 bg-primary d-flex flex-column justify-content-center align-items-center">
                        <div className="col-11 pt-sm-5 py-4 ps-2 text-light">
                            <h3 className="text-secondary">Eagle Seek - Career Advisor</h3>
                            <p>September 2022</p>
                            <p className="text-info">
                                A quick survey students can take to find an academic pursue that fits them. Users are asked a series of questions similar to a MBTI assessment. Each question puts a weight on an attribute that we store in the backend. In the end, we sum those weights to get a ranking of best-matched majors.
                            </p>
                            <a href="https://www.hacktheheights.org/" className="btn btn-secondary" role="button" target="_blank">Learn more</a>
                            <div className="mt-3">
                                <span className="badge badge-primary me-2 bg-light text-primary">REACT</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Express.js</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Docker</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Javascript</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Kubernetes</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-md-flex justify-content-between pb-md-0 pb-5">
                    <div className="col-md-5 bg-light">
                        <img className="col-12" src={require('../assets/project1.png')} alt="" />
                    </div>
                    <div className="col-md-5 bg-primary d-flex flex-column justify-content-center align-items-center">
                        <div className="col-11 pt-sm-5 py-4 ps-2 text-light">
                            <h3 className="text-secondary">Hack The Heights App</h3>
                            <p>July 2022</p>
                            <p className="text-info">
                                A landing page for people to sign up for events and workshops during the BC Hackathon. Users can browse events they like with a short description and sign-up deadline, and submit feedback forms at the end. 
                            </p>
                            <a href="https://www.hacktheheights.org/" className="btn btn-secondary" role="button" target="_blank">Learn more</a>
                            <div className="mt-3">
                                <span className="badge badge-primary me-2 bg-light text-primary">REACT</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Express.js</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">MongoDB</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Node.js</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-md-flex justify-content-between pb-md-0 pb-5">
                    <div className="col-md-5 bg-light">
                        <img className="col-12" src={require('../assets/project2.png')} alt="" />
                    </div>
                    <div className="col-md-5 bg-primary d-flex flex-column justify-content-center align-items-center">
                        <div className="col-11 pt-sm-5 py-4 ps-2 text-light">
                            <h3 className="text-secondary">Crypto Learning App</h3>
                            <p>Jan. 2021</p>
                            <p className="text-info"> A learning app that teaches basic and intermediate knowledge of blockchain topics using images quizzes, and a progress tracker. Beginners can choose from 3 levels of difficulty for the lessons and see their progress in a pie chart in the profile.</p>
                            <a href="https://genisys.glitch.me/" className="btn btn-secondary" role="button" target="_blank">Learn more</a>
                            <div className="mt-3">
                                <span className="badge badge-primary me-2 bg-light text-primary">React</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">AdobeXD</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Express.js</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Firebase</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Node.js</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-md-flex justify-content-between pb-md-0 pb-5">
                    <div className="col-md-5 bg-light">
                        <img className="col-12" src={require('../assets/project3.png')} alt="" />
                    </div>
                    <div className="col-md-5 bg-primary d-flex flex-column justify-content-center align-items-center">
                        <div className="col-11 pt-sm-5 py-4 ps-2 text-light">
                            <h3 className="text-secondary">Art Exhibition App</h3>
                            <p>August 2020</p>
                            <p className="text-info">
                                A web app that showcases artworks from the senior art show of 2020 at Boston College.
                                It combines utility with design to bring an interactive gallery experience for users.
                            </p>
                            <a href="https://sammy2023.github.io/" className="btn btn-secondary" role="button" target="_blank">Learn more</a>
                            <div className="mt-3">
                                <span className="badge badge-primary me-2 bg-light text-primary">HTML5</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Bootstrap</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Github</span>
                                <span className="badge badge-primary me-2 bg-light text-primary">Node.js</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}
        </section>
    )
}

export default Project; 