import React from "react";

const Job = () => {
    return(
        <section className="my-4 my-sm-5 pt-sm-5" id="experience">
            <div className="container">
                <h2 className="text-center text-secondary my-5">
                    JOB EXPERIENCE
                </h2>
                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-sm-start align-items-center">
                    <div className="col-sm-5 col-11 mb-sm-0 mb-5 border-top border-3 border-primary text-primary">
                        <h3 className="my-3">Full Stack Devevloper @Boston College ITS Office <br /> June 2022 - May 2023</h3>
                        <p className="m-0">
                            Utilized Java and Tomcat to update and transfer course modules from the old student information system 
                            to the new platform hosted on Oracle, enhancing the overall user experience and data structure.
                        </p>
                        <br />
                        <br />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-quote" viewBox="0 0 16 16">
                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                        </svg>
                        <p className="m-0">
                            Exhibited a strong ability to learn quickly and delivered quick solutions to the team's need.
                              Having a problem solver that not only works creatively but communicates effectively really 
                              expedited the team progress.
                        </p>
                        <figcaption className="blockquote-footer mt-2">
                            Norman Wright <cite title="Source Title"> Principal Applications Engineer @ Boston College </cite>
                        </figcaption>
                    </div>
                    <div className="col-sm-5 col-11 mb-sm-0 mb-5 border-top border-3 border-primary text-primary">
                        <h3 className="my-3">Software Engineer Intern @Script Health <br /> Aug 2021 - Oct 2021</h3>
                        <p className="m-0">
                            Implemented new functionality to existing chat page such as
                            message search and incoming message notifications, resulting in user engagement by ~ 20%.
                        </p>
                        <br />
                        <p className="m-0">
                            Worked in a fast-paced environment, collaborating with backend 
                            developers and the design team to maintain and update the company's frontend architecture. 
                        </p>
                        
                        <br></br>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-quote" viewBox="0 0 16 16">
                        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"/>
                        </svg>
                        <p className="m-0">
                            Sam was able to wear multiple hats and contribute to the frontend team's need while working indepedently.
                        </p>
                        <figcaption className="blockquote-footer mt-2">
                            Moh Noori <cite title="Source Title"> Founder and CEO @ ScriptChain Health </cite>
                        </figcaption>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Job;