const coding_projects = [
    {
        id: 1,
        title : "Boston College Hackathon Website",
        description : "This is a landing page for people to sign up for Hackathon events and workshops. It was designed in Figma and implemented with React. Users can browse events they like with a short description and sign-up deadline, and submit feedback forms at the end",
        skill_list : ["React", "Bootstrap", "Node.js", "Express", "MySQL"],
        hasLink : true,
        hasCode : false,
        thumbnail : require("../assets/thumbnail1.png"),
        image : require("../assets/projectfull_1.png"),
        website_link : "https://www.hacktheheights.org/",
        date : "March 2023"
    },
    {
        id: 4,
        title : "Career site for Teaching Assistant Roles",
        description : "A site for students to send applications to a course they want to be TA for. Students can filter application based on courses they took and professor can reject or accept applications, which sends an email notification to students.",
        skill_list : ["Python", "Django", "SQLite", "Crispy Forms"],
        hasLink : false,
        hasCode : false,
        website_link : "",
        thumbnail : require("../assets/thumbnail4.png"),
        image : require("../assets/projectfull_4.png"),
        date : "October 2023"
    },
    {
        id: 2,
        title : "Crypto Learning Platform for Beginner",
        description : "A learning app that teaches basic and intermediate knowledge of blockchain topics using images quizzes, and a progress tracker. It was designed with AdobeXD and implemented with React. Beginners can choose from 3 levels of difficulty for the lessons and see their progress in a pie chart in the profile.",
        skill_list : ["React", "Bootstrap", "Node.js", "Express"],
        hasLink : true,
        hasCode : false,
        website_link : "https://genisys.glitch.me/",
        thumbnail : require("../assets/thumbnail2.png"),
        image : require("../assets/projectfull_2.png"),
        date : " January 2022"
    },
    {
        id: 3,
        title : "Visualized Sign Language Transcriber ",
        description : "The translator captures sign gesture from the camera and translates it letter by letter to a text-box. To detect the hand(s) of the user, we used the open-source framework, Google MediaPipe. Once located, the image patch containing the hand is first inverted into the RGB color space in order to be processed by the landmark detection function in mediapipe",
        skill_list : ["Python", "Tensorflow", "Google MediaPipe", "OpenCV"],
        hasLink : false,
        hasCode : false,
        website_link : "",
        thumbnail : require("../assets/thumbnail3.png"),
        image : require("../assets/projectfull_3.gif"),
        date : "August 2022"
    },
    {
        id: 5,
        title : "Gallery site for personal artworks",
        description : "A site dedicated to showcasing some of the artworks I have done since high school. Users explore portrait, landscape, abstract, and digital collections. Each page is rendered with animations and descriptions for an immersive gallery experience.",
        skill_list : ["HTML", "CSS", "React", "Bootstrap"],
        hasLink : true,
        hasCode : false,
        website_link : "https://sammy2023.github.io/",
        thumbnail : require("../assets/thumbnail5.png"),
        image : require("../assets/projectfull_5.png"),
        date : "June 2021"
    }
]

export default coding_projects;