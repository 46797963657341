import React from "react";
import css from "../style/Home.module.css";

const Home = () => {
    return (
        <section className="bg-primary text-light pt-3 text-center text-sm-start" id="home">
            <div className="container">
                <div className="d-md-flex justify-content-between">
                    <div className="bg-primary text-info d-flex col-md-5 flex-column justify-content-center">
                        <p>WELCOME TO MY WEBSITE</p>
                        <h2>Hi, I'm <span class="text-secondary">Sammy Lee</span></h2>
                        <p>Software Developer / UI & UX Designer based in Boston</p>
                        <div className="mb-4 mb-md-0">
                            <a href="http://www.linkedin.com/in/sammy-lee2022" className="" style={{textDecoration: 'none', color: "#DCE6FC"}}>
                                <i class="devicon-linkedin-plain me-3" style={{fontSize: '30px'}}></i>
                            </a>
                            <a href="https://github.com/Sammy2023" style={{textDecoration: 'none', color: "#DCE6FC"}}>
                                <i class="devicon-github-original" style={{fontSize: '30px'}}></i>
                            </a>
                        </div>
                    </div>
                    <div className="bg-info d-flex col-md-6 justify-content-center py-4">
                        <img className=" d-sm-block w-50" style={{maxWidth: '300px'}} src={require('../assets/profile.png')} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home;