import React from "react";
import Resume from '../assets/resume3.pdf'

const Footer = () => {
    return (
        <section className="py-4 mt-5 bg-primary">
            <div className="container py-5 text-info">
                <div className="row">
                    <h1>Let's <span className="text-secondary">Connect!</span></h1>
                    <div className="d-sm-flex my-4 justify-content-between">
                        <div className="col-sm-5 mb-sm-0 mb-5 pt-3 border-top border-3 border-secondary text-info">
                            <p className="m-0">EMAIL: </p>
                            <p className="m-0">leedzc@bc.edu</p>
                        </div>
                        <div className="col-sm-5 mb-sm-0 mb-5 pt-3 border-top border-3 border-secondary text-info">
                            <p className="m-0">LOCATION: </p>
                            <p className="m-0">Malden, Massachusetts</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <a href={Resume} className="btn btn-secondary" role="button" target="_blank">Review Resume</a>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Footer;